import { Drawer, Navbar, Sidebar } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import LogoComponent from '../components/LogoComponent';
import { FiMenu, FiX } from 'react-icons/fi';
import DarkModeToggle from '../components/DarkModeToggle';
import TruncatedText from '../components/TruncatedText';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import StyledText from '../components/styledText';
import YouTube from 'react-youtube';
import { toast } from 'react-toastify';
import { logo, name, serverURL } from '../constants';
import axios from 'axios';
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { HiDownload } from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";
import jsPDF from 'jspdf';
import "../fontBold";
import "../fontMedium";
import html2pdf from 'html2pdf.js';
import ChatWidget from '../components/chatWidget';

const Course = () => {

    //EXAM
    const [isOpen, setIsOpen] = useState(false);
    const [key, setkey] = useState('');
    const { state } = useLocation();
    const { mainTopic, type, courseId, end, level } = state || {};
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const storedTheme = sessionStorage.getItem('darkMode');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [theory, setTheory] = useState('');
    const [media, setMedia] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isComplete, setIsCompleted] = useState(false);
    const [isCompletePartial, setIsCompletedPartial] = useState(false);
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [examJSON, setExamJSON] = useState({});
    const [getQuizResults, setQuizResults] = useState([]);


    function redirectHome() {
        navigate("/home");
    }

    async function htmlDownload() {
        if (!isCompletePartial) {
            toast.error("Please visit each topic to export");
        } else {
            const id = toast.loading("Please wait exporting...")
            // Generate the combined HTML content
            const combinedHtml = await getCombinedHtml(mainTopic, jsonData[mainTopic.toLowerCase()]);

            // Create a temporary div element
            const tempDiv = document.createElement('div');
            tempDiv.style.width = '100%';  // Ensure div is 100% width
            tempDiv.style.height = '100%';  // Ensure div is 100% height
            tempDiv.innerHTML = combinedHtml;
            document.body.appendChild(tempDiv);

            // Create the PDF options
            const options = {
                filename: `${mainTopic}.pdf`,
                image: { type: 'jpeg', quality: 1 },
                margin: [15, 15, 15, 15],
                pagebreak: { mode: ["avoid-all", "css", "legacy"] },
                html2canvas: {
                    scale: 2,
                    logging: false,
                    scrollX: 0,
                    scrollY: 0,
                    useCORS: true
                },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            };

            // Generate the PDF
            html2pdf().from(tempDiv).set(options).save().then(() => {
                // Save the PDF
                document.body.removeChild(tempDiv);
                toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
            });
        }
    }


    async function getCombinedHtml(mainTopic, topics) {


        async function toDataUrl(url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                xhr.onload = function () {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };

                xhr.onerror = function () {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                };

                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
            }).catch(error => {
                console.error(`Failed to fetch image at ${url}:`, error);
                return ''; // Fallback or placeholder
            });
        }

        const topicsHtml = topics.map(topic => `
            <h3 style="font-size: 18pt; font-weight: bold; margin: 0; margin-top: 15px;">${topic.title}</h3>
            ${topic.subtopics.map(subtopic => `
                <p style="font-size: 16pt; margin-top: 10px;">${subtopic.title}</p>
            `).join('')}
        `).join('');

        const theoryPromises = topics.map(async topic => {
            const subtopicPromises = topic.subtopics.map(async (subtopic, index, array) => {
                const imageUrl = type === 'text & image course' ? await toDataUrl(subtopic.image) : '';
                return `
                    <div> 
                        <p style="font-size: 16pt; margin-top: 20px; font-weight: bold;">
                            ${subtopic.title}
                        </p>
                        <div style="font-size: 12pt; margin-top: 15px; ">
                        ${type === 'text & image course'
                        ? (imageUrl ? `<img style="margin-top: 10px;" src="${imageUrl}">` : '')
                        : `<a style="color: #0000FF;" href="https://www.youtube.com/watch?v=${subtopic.youtube}" target="_blank" rel="noopener noreferrer">Watch the YouTube video on ${subtopic.title}</a>`}
                            <div style="margin-top: 10px;">${subtopic.theory}</div>
                        </div>
                    </div>
                `;
            });
            const subtopicHtml = await Promise.all(subtopicPromises);
            return `
                <div style="margin-top: 30px;">
                    <h3 style="font-size: 18pt; text-align: center; font-weight: bold; margin: 0;">
                        ${topic.title}
                    </h3>
                    ${subtopicHtml.join('')}
                </div>
            `;
        });
        const theoryHtml = await Promise.all(theoryPromises);

        return `
        <div class="html2pdf__page-break" 
             style="display: flex; align-items: center; justify-content: center; text-align: center; margin: 0 auto; max-width: 100%; height: 11in;">
            <h1 style="font-size: 30pt; font-weight: bold; margin: 0;">
                ${mainTopic}
            </h1>
        </div>
        <div class="html2pdf__page-break" style="text-align: start; margin-top: 30px; margin-right: 16px; margin-left: 16px;">
            <h2 style="font-size: 24pt; font-weight: bold; margin: 0;">Index</h2>
            <br>
            <hr>
            ${topicsHtml}
        </div>
        <div style="text-align: start; margin-right: 16px; margin-left: 16px;">
            ${theoryHtml.join('')}
        </div>
        `;
    }


    const CountDoneTopics = async () => {
        let doneCount = 0;
        let totalTopics = 0;
        let quiz = 0;
        jsonData[mainTopic.toLowerCase()].forEach((topic) => {
            quiz++;
            topic.subtopics.forEach((subtopic) => {

                if (subtopic.done) {
                    doneCount++;
                }
                totalTopics++;
            });
        });

        const completionPartialPercentage = Math.round((doneCount / totalTopics) * 100);
        if (completionPartialPercentage >= '100') {
            setIsCompletedPartial(true);
        }

        totalTopics = totalTopics + quiz;
        const quizCount = await getQuiz();
        doneCount = doneCount + quizCount;
        const completionPercentage = Math.round((doneCount / totalTopics) * 100);
        setPercentage(completionPercentage);
        if (completionPercentage >= '100') {
            setIsCompleted(true);
        }
    }

    async function getQuiz() {
        const postURL = serverURL + '/api/getresult';
        const response = await axios.post(postURL, { courseId });
        if (response.data.success) {
            sessionStorage.setItem('result', response.data.message);
            const jsonGetQuiz = JSON.parse(response.data.message);
            const keys = Object.keys(jsonGetQuiz);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (!getQuizResults.find((item) => item === key)) {
                    getQuizResults.push(key);
                }
            }
            return Object.keys(jsonGetQuiz).length;
        } else {
            return 0;
        }
    }

    const opts = {
        height: '390',
        width: '640',
    };

    const optsMobile = {
        height: '250px',
        width: '100%',
    };

    async function finish() {
        if (sessionStorage.getItem('first') === 'true') {
            if (!end) {
                const today = new Date();
                const formattedDate = today.toLocaleDateString('en-GB');
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate, jsonData, level, mail: false } });
            } else {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: end, jsonData, level, mail: false } });
            }

        } else {
            const dataToSend = {
                courseId: courseId
            };
            try {
                const postURL = serverURL + '/api/finish';
                const response = await axios.post(postURL, dataToSend);
                if (response.data.success) {
                    const today = new Date();
                    const formattedDate = today.toLocaleDateString('en-GB');
                    sessionStorage.setItem('first', 'true');
                    sendEmail(formattedDate);
                } else {
                    finish()
                }
            } catch (error) {
                finish()
            }
        }
    }

    async function sendEmail(formattedDate) {

        navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate, jsonData, level, mail: true } });

    }

    useEffect(() => {
        loadMessages()
        const CountDoneTopics = async () => {
            let doneCount = 0;
            let totalTopics = 0;
            let quiz = 0;
            jsonData[mainTopic.toLowerCase()].forEach((topic) => {
                quiz++;
                topic.subtopics.forEach((subtopic) => {

                    if (subtopic.done) {
                        doneCount++;
                    }
                    totalTopics++;
                });
            });
            const completionPartialPercentage = Math.round((doneCount / totalTopics) * 100);
            if (completionPartialPercentage >= '100') {
                setIsCompletedPartial(true);
            }
            totalTopics = totalTopics + quiz;
            const quizCount = await getQuiz();
            doneCount = doneCount + quizCount;
            const completionPercentage = Math.round((doneCount / totalTopics) * 100);
            setPercentage(completionPercentage);
            if (completionPercentage >= '100') {
                setIsCompleted(true);
            }
        }

        if (!mainTopic) {
            navigate("/create");
        } else {
            if (percentage >= '100') {
                setIsCompleted(true);
            }

            const mainTopicData = jsonData[mainTopic.toLowerCase()][0];
            const firstSubtopic = mainTopicData.subtopics[0];
            firstSubtopic.done = true
            setSelected(firstSubtopic.title)
            setTheory(firstSubtopic.theory)

            if (type === 'video & text course') {
                setMedia(firstSubtopic.youtube);
            } else {
                setMedia(firstSubtopic.image)

            }
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            CountDoneTopics();

        }

    }, []);

    const handleSelect = (topics, sub) => {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);

        if (mSubTopic.theory === '' || mSubTopic.theory === undefined || mSubTopic.theory === null) {
            if (type === 'video & text course') {

                const query = `${mSubTopic.title} ${mainTopic} in english`;
                const id = toast.loading("Please wait...")
                sendVideo(query, topics, sub, id, mSubTopic.title);

            } else {

                const prompt = `Explain for a ${level} grade student about this subtopic of ${mainTopic} with examples :- ${mSubTopic.title}. Please Strictly Don't Give Additional Resources And Images.`;
                const promptImage = `Example of ${mSubTopic.title} in ${mainTopic} png`;
                const id = toast.loading("Please wait...")
                sendPrompt(prompt, promptImage, topics, sub, id);

            }
        } else {
            setSelected(mSubTopic.title)
            setTheory(mSubTopic.theory)
            if (type === 'video & text course') {
                setMedia(mSubTopic.youtube);
            } else {
                setMedia(mSubTopic.image)
            }
            mSubTopic.done = true;
            updateCourse();
        }

    };

    async function sendPrompt(prompt, promptImage, topics, sub, id) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            try {
                const parsedJson = htmlContent;
                sendImage(parsedJson, promptImage, topics, sub, id);
            } catch (error) {
                sendPrompt(prompt, promptImage, topics, sub, id)
            }

        } catch (error) {
            sendPrompt(prompt, promptImage, topics, sub, id)
        }
    }

    async function sendImage(parsedJson, promptImage, topics, sub, id) {
        const dataToSend = {
            prompt: promptImage,
        };
        try {
            const postURL = serverURL + '/api/image';
            const res = await axios.post(postURL, dataToSend);
            try {
                const generatedText = res.data.url;
                sendData(generatedText, parsedJson, topics, sub, id);
            } catch (error) {
                sendImage(parsedJson, promptImage, topics, sub, id)
            }

        } catch (error) {
            sendImage(parsedJson, promptImage, topics, sub, id)
        }
    }

    async function sendData(image, theory, topics, sub, id) {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory
        mSubTopic.image = image;
        setSelected(mSubTopic.title)

        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory)
        if (type === 'video & text course') {
            setMedia(mSubTopic.youtube);
        } else {
            setMedia(image)
        }
        mSubTopic.done = true;
        updateCourse();
    }

    async function sendDataVideo(image, theory, topics, sub, id) {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory
        mSubTopic.youtube = image;
        setSelected(mSubTopic.title)

        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory)
        if (type === 'video & text course') {
            setMedia(image);
        } else {
            setMedia(mSubTopic.image)
        }
        mSubTopic.done = true;
        updateCourse();

    }

    async function updateCourse() {
        CountDoneTopics();
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        const dataToSend = {
            content: JSON.stringify(jsonData),
            courseId: courseId
        };
        try {
            const postURL = serverURL + '/api/update';
            await axios.post(postURL, dataToSend);
        } catch (error) {
            updateCourse();
        }
    }

    async function sendVideo(query, mTopic, mSubTopic, id, subtop) {
        const dataToSend = {
            prompt: query,
        };
        try {
            const postURL = serverURL + '/api/yt';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                sendTranscript(generatedText, mTopic, mSubTopic, id, subtop);
            } catch (error) {
                sendVideo(query, mTopic, mSubTopic, id, subtop)
            }

        } catch (error) {
            sendVideo(query, mTopic, mSubTopic, id, subtop)
        }
    }

    async function sendTranscript(url, mTopic, mSubTopic, id, subtop) {
        const dataToSend = {
            prompt: url,
        };
        try {
            const postURL = serverURL + '/api/transcript';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                const allText = generatedText.map(item => item.text);
                const concatenatedText = allText.join(' ');
                const prompt = `Summarize this theory in a teaching way :- ${concatenatedText}.`;
                sendSummery(prompt, url, mTopic, mSubTopic, id);
            } catch (error) {
                const prompt = `Explain for a ${level} grade student about this subtopic of ${mainTopic} with examples :- ${subtop}. Please Strictly Don't Give Additional Resources And Images.`;
                sendSummery(prompt, url, mTopic, mSubTopic, id);
            }

        } catch (error) {
            const prompt = `Explain for a ${level} grade student about this subtopic of ${mainTopic} with examples :- ${subtop}.  Please Strictly Don't Give Additional Resources And Images.`;
            sendSummery(prompt, url, mTopic, mSubTopic, id);
        }
    }

    async function sendSummery(prompt, url, mTopic, mSubTopic, id) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            try {
                const parsedJson = htmlContent;
                sendDataVideo(url, parsedJson, mTopic, mSubTopic, id);
            } catch (error) {
                sendSummery(prompt, url, mTopic, mSubTopic, id)
            }

        } catch (error) {
            sendSummery(prompt, url, mTopic, mSubTopic, id)
        }
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleOpenClose = (keys) => {
        setIsOpen(!isOpen)
        setkey(keys);
    };

    const defaultMessage = `<p>Hey there! I'm your AI teacher. If you have any questions about your ${mainTopic} course, whether it's about videos, images, or theory, just ask me. I'm here to clear your doubts.</p>`;
    const defaultPrompt = `I have a doubt about this topic :- ${mainTopic}. Please clarify my doubt in very short :- `;

    const loadMessages = async () => {
        try {
            const jsonValue = sessionStorage.getItem(mainTopic);
            if (jsonValue !== null) {
                setMessages(JSON.parse(jsonValue));
            } else {
                const newMessages = [...messages, { text: defaultMessage, sender: 'bot' }];
                setMessages(newMessages);
                await storeLocal(newMessages);
            }
        } catch (error) {
            loadMessages();
        }
    };

    const sendMessage = async () => {
        if (newMessage.trim() === '') return;

        const userMessage = { text: newMessage, sender: 'user' };
        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        await storeLocal(updatedMessages);
        setNewMessage('');

        let mainPrompt = defaultPrompt + newMessage;
        const dataToSend = { prompt: mainPrompt };
        const url = serverURL + '/api/chat';

        try {
            const response = await axios.post(url, dataToSend);

            if (response.data.success === false) {
                sendMessage();
            } else {
                const botMessage = { text: response.data.text, sender: 'bot' };
                const updatedMessagesWithBot = [...updatedMessages, botMessage];
                setMessages(updatedMessagesWithBot);
                await storeLocal(updatedMessagesWithBot);
            }
        } catch (error) {

        }
    };

    async function storeLocal(messages) {
        try {
            sessionStorage.setItem(mainTopic, JSON.stringify(messages));
        } catch (error) {
            sessionStorage.setItem(mainTopic, JSON.stringify(messages));
        }
    }

    async function takeTest(topic) {
        if (examJSON.length > 0) {
            const id = toast.loading("Please wait...");
            checkExamExist(topic, id, examJSON);
        } else {
            const id = toast.loading("Please wait...");
            try {
                const postURL = serverURL + '/api/getexams';
                const response = await axios.post(postURL, { courseId });
                if (response.data !== '') {
                    const exam = JSON.parse(response.data);
                    setExamJSON(exam);
                    checkExamExist(topic, id, exam);
                } else {
                    checkExamExist(topic, id, {});
                }
            } catch (error) {
                console.log(error)
                toast.update(id, { render: "Internal Server Error", type: "error", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
            }
        }

    }

    async function checkExamExist(topic, id, exam) {
        if (JSON.stringify(exam).length > 0) {
            if (exam[topic]) {
                toast.update(id, { render: "Quiz Ready", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
                const questions = exam[topic];
                const element = document.documentElement; // or you can use a specific container if you want
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) { // Firefox
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) { // IE/Edge
                    element.msRequestFullscreen();
                } else {
                    console.error('Full-screen mode is not supported by this browser.');
                }
                navigate('/exam', { state: { topic: topic, courseId: courseId, questions: questions, numberOfQuestions: questions.length } });
            } else {
                getExamQuestions(topic, id, exam);
            }
        } else {
            getExamQuestions(topic, id, exam);

        }

    }


    async function getExamQuestions(topic, id, exam) {
        //GET QUESTIONS
        const mainTopicExam = jsonData[mainTopic.toLowerCase()];
        let subtopicsString = '';
        mainTopicExam.map((topicTemp) => {
            if (topicTemp.title === topic) {
                topicTemp.subtopics.map((subtopics) => {
                    let titleOfSubTopic = subtopics.title;
                    subtopicsString = subtopicsString + ' , ' + titleOfSubTopic;
                });
            }
        });

        const prompt = `generate a MCQ quiz on topic title ${topic} based on each sub topics :- ${subtopicsString}, One question per sub topic. Add options A, B, C, D and only one correct answer. Give your repones Strictly inJSON format like this :-
        {
          "${topic}": [
            {
              "topic": "sub topic title",
              "question": "",
              "options": [
               "",
               "",
               "",
               ""
              ],
              "answer": ""
            },
            {
              "topic": "sub topic title",
              "question": "",
              "options": [
               "",
               "",
               "",
               ""
              ],
              "answer": ""
            },
            {
              "topic": "sub topic title",
              "question": "",
              "options": [
               "",
               "",
               "",
               ""
              ],
              "answer": ""
            }
          ]
        }`;

        try {
            const existingExam = JSON.stringify(exam);
            const postURL = serverURL + '/api/aiexam';
            const response = await axios.post(postURL, { courseId, existingExam, prompt });
            const jsonExaming = JSON.parse(response.data.demoText);
            checkExamExist(topic, id, jsonExaming);
        } catch (error) {
            console.log(error)
        }
    }

    const style = {
        "root": {
            "base": "h-full",
            "collapsed": {
                "on": "w-16",
                "off": "w-64"
            },
            "inner": "no-scrollbar h-full overflow-y-auto overflow-x-hidden rounded-none border-black dark:border-white md:border-r  bg-white py-4 px-3 dark:bg-black"
        }
    }


    const renderTopicsAndSubtopics = (topics) => {
        try {
            return (
                <div>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.title}>
                            <div className="relative inline-block text-left " >
                                <button
                                    onClick={() => handleOpenClose(topic.title)}
                                    type="button"
                                    className="inline-flex text-start text-base w-71 font-bold  text-black dark:text-white"
                                >
                                    {topic.title}
                                    <IoIosArrowDown className="mr-2 ml-2 h-3 w-3 mt-2" />
                                </button>

                                {isOpen && key === topic.title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.title}

                                                    onClick={() => handleSelect(topic.title, subtopic.title)}
                                                    className="cursor-pointer flex py-2 text-sm flex-row items-center font-normal text-black dark:text-white  text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>

                                            ))}
                                        </div>
                                        <div className='flex flex-row items-center' onClick={() => takeTest(topic.title)}>
                                            <p className='border-b-0 text-black font-normal  border-sky-400 dark:text-white dark:border-white hover:bg-white rounded-3xl dark:hover:bg-sky-400 hover:text-black md:hover:text-black dark:hover:text-white dark:md:hover:text-white' style={{ borderWidth: '1px', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}>Take Quiz</p>
                                            {getQuizResults.find((item) => item === key) ? <FaCheck className='ml-2' size={12} /> : <></>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        } catch (error) {
            return (
                <div>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.Title}>
                            <div className="relative inline-block text-left " >
                                <button
                                    onClick={() => handleOpenClose(topic.Title)}
                                    type="button"
                                    className="inline-flex text-start text-base w-71 font-bold  text-black dark:text-white"
                                >
                                    {topic.Title}
                                    <IoIosArrowDown className="mr-2 ml-2 h-3 w-3 mt-2" />
                                </button>

                                {isOpen && key === topic.Title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.Subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.Title}
                                                    onClick={() => handleSelect(topic.Title, subtopic.Title)}
                                                    className=" cursor-pointer flex py-2 flex-row text-sm items-center font-normal text-black dark:text-white  text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.Title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>
                                            ))}
                                        </div>
                                        <div className='flex flex-row items-center' onClick={() => takeTest(topic.title)}>
                                            <p className='border-b-0 text-black font-normal  border-sky-400 dark:text-white dark:border-sky-400 hover:bg-white rounded-3xl dark:hover:bg-black hover:text-black md:hover:text-black dark:hover:text-white dark:md:hover:text-white' style={{ borderWidth: '1px', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}>Take Quiz</p>
                                            {getQuizResults.find((item) => item === key) === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        }
    };

    return (
        <>
            {!mainTopic ? <></>
                :
                <div>
                    <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
                        <div className={`fixed inset-0 cursor-pointer  bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
                        <div className="flex-1 flex flex-col overflow-hidden">

                            <div>
                                <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                    <Navbar.Brand className='ml-1'>

                                        {isComplete ?
                                            <p onClick={finish} className=' cursor-pointer mr-3 underline text-black dark:text-white font-normal'>Certificate</p>
                                            :
                                            <div className='w-7 h-7 mr-3'>
                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        strokeLinecap: 'butt',
                                                        textSize: '20px',
                                                        pathTransitionDuration: 0.5,
                                                        pathColor: storedTheme === "true" ? 'skyblue' : 'skyblue',
                                                        textColor: storedTheme === "true" ? '#fff' : '#000',
                                                        trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                    })}
                                                />
                                            </div>
                                        }

                                        <TruncatedText text={mainTopic} len={6} />
                                    </Navbar.Brand>
                                    <div className='flex md:hidden justify-center items-center'>
                                        <div className='mx-2' onClick={redirectHome}><AiFillHome size={20} color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'} /></div>
                                        <DarkModeToggle className='inline-flex items-center md:hidden' />
                                        {isSidebarOpen ? (
                                            <FiX
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        ) : (
                                            <FiMenu
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        )}
                                    </div>
                                    <Navbar.Collapse>
                                        <div className='hidden md:flex justify-center items-center mb-2 mt-2'>
                                            <DarkModeToggle />
                                        </div>
                                    </Navbar.Collapse>
                                </Navbar>

                            </div>

                            <Sidebar
                                aria-label="Default sidebar example"
                                theme={style}
                                className={`md:border-r md:border-black md:dark:border-white dark:bg-black fixed inset-y-0 left-0 w-64  bg-white z-50 overflow-y-auto transition-transform transform lg:translate-x-0 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                            >
                                <LogoComponent isDarkMode={storedTheme} />
                                <Sidebar.Items className='mt-6'>

                                    <div className='mb-6'>
                                        {isComplete ?
                                            <>
                                                <Sidebar.ItemGroup>
                                                    <div className="relative inline-block text-left justify-center" >
                                                        <button
                                                            onClick={() => htmlDownload()}
                                                            type="button"
                                                            className="inline-flex justify-center items-center text-center text-sm px-2 w-71 h-14 font-bold rounded-3xl bg-sky-400 dark:bg-sky-400  text-white"
                                                        >
                                                            DOWNLOAD COURSE
                                                            <HiDownload className="ml-2 text-base" />
                                                        </button>
                                                    </div>
                                                </Sidebar.ItemGroup>
                                                <br />
                                                <hr />
                                            </>
                                            :
                                            <></>}
                                    </div>


                                    {jsonData && getQuizResults && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}

                                </Sidebar.Items>
                            </Sidebar>
                            <div className='mx-5 overflow-y-auto bg-white dark:bg-black'>
                                <p className='font-black text-black dark:text-white text-lg'>{selected}</p>

                                <div className='overflow-hidden mt-5 text-black dark:text-white text-base pb-10 max-w-full'>
                                    {type === 'video & text course' ?
                                        <div>
                                            <YouTube key={media} className='mb-5' videoId={media} opts={optsMobile} />
                                            <StyledText text={theory} />
                                        </div>

                                        :
                                        <div>
                                            <StyledText text={theory} />
                                            <img className='overflow-hidden p-10' src={media} alt="Media" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row overflow-y-auto h-screen max-md:hidden'>
                        <Sidebar
                            theme={style}
                            aria-label="Default sidebar example">
                            <LogoComponent isDarkMode={storedTheme} />
                            <Sidebar.Items className='mt-6'>

                                <div className='mb-6'>
                                    {isComplete ?
                                        <>
                                            <Sidebar.ItemGroup>
                                                <div className="relative inline-block text-left justify-center" >
                                                    <button
                                                        onClick={() => htmlDownload()}
                                                        type="button"
                                                        className="inline-flex justify-center items-center text-center text-sm px-2 w-71 h-14 font-bold rounded-3xl bg-sky-400 dark:bg-sky-400  text-white"
                                                    >
                                                        DOWNLOAD COURSE
                                                        <HiDownload className="ml-2 text-base" />
                                                    </button>
                                                </div>
                                            </Sidebar.ItemGroup>
                                            <br />
                                            <hr />
                                        </>
                                        :
                                        <></>}
                                </div>

                                {jsonData && getQuizResults && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}


                            </Sidebar.Items>
                        </Sidebar>
                        <div className='overflow-y-auto flex-grow flex-col'>
                            <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                <Navbar.Brand className='ml-1'>
                                    {isComplete ?
                                        <p onClick={finish} className=' cursor-pointer mr-3 underline text-black dark:text-white font-normal'>Download Certificate</p> :
                                        <div className='w-8 h-8 mr-3'>
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    pathTransitionDuration: 0.5,
                                                    pathColor: storedTheme === "true" ? 'skyblue' : 'skyblue',
                                                    textColor: storedTheme === "true" ? '#fff' : '#000',
                                                    trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                })}
                                            />
                                        </div>
                                    }
                                    <TruncatedText text={mainTopic} len={10} />
                                </Navbar.Brand>
                                <Navbar.Collapse>
                                    <div className='hidden md:flex justify-center items-center mb-2 mt-2'>
                                        <p onClick={redirectHome} className='font-medium text-black dark:text-white text-base mb-1 mr-2'>Home</p>
                                        <DarkModeToggle />
                                    </div>
                                </Navbar.Collapse>
                            </Navbar>
                            <div className='px-5 bg-white dark:bg-black pt-5'>
                                <p className='font-black text-black dark:text-white text-xl'>{selected}</p>

                                <div className='overflow-hidden mt-5 text-black dark:text-white text-base pb-10 max-w-full'>

                                    {type === 'video & text course' ?
                                        <div>
                                            <YouTube key={media} className='mb-5' videoId={media} opts={opts} />
                                            <StyledText text={theory} />
                                        </div>

                                        :
                                        <div>
                                            <StyledText text={theory} />
                                            <img className='overflow-hidden p-10' src={media} alt="Media" />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <ChatWidget defaultMessage={defaultMessage} defaultPrompt={defaultPrompt} mainTopic={mainTopic} />
                </div>
            }
        </>
    );
};


export default Course;