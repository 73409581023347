import { Table, Button } from 'flowbite-react';
import React from 'react';
import { toast } from 'react-toastify';
import { serverURL } from '../../constants';
import axios from 'axios';

const UserTable = ({ datas, account }) => {

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        window.location.reload();
    }


    async function enableAccount(uid) {
        const postURL = serverURL + '/api/enable';
        const response = await axios.post(postURL, { uid: uid });
        if (response.data.success) {
            showToast(response.data.message);
        }
    }

    async function disableAccount(uid) {
        const postURL = serverURL + '/api/disable';
        const response = await axios.post(postURL, { uid: uid });
        if (response.data.success) {
            showToast(response.data.message);
        }
    }

    return (
        <div className='flex flex-col py-4'>
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head className='border-b text-black'>
                        <Table.HeadCell className='font-black'>Email</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Name</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Type</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Account</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {datas.map(user => (
                            <Table.Row key={user._id} className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">
                                    {user.email}
                                </Table.Cell>
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">{user.mName}</Table.Cell>
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">{user.type}</Table.Cell>
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">
                                    {account ? (
                                        <>
                                            {account.some(acc => acc.user === user._id) ? (

                                                <Button onClick={() => enableAccount(user._id)} className='items-center justify-center text-center   bg-green-600 text-white font-bold rounded-3xl w-full enabled:hover:bg-green-600 enabled:focus:bg-green-600 enabled:focus:ring-transparent dark:enabled:hover:bg-green-600 dark:enabled:focus:bg-green-600 dark:enabled:focus:ring-transparent mt-6'>
                                                    Enable
                                                </Button>
                                            ) : (

                                                <Button onClick={() => disableAccount(user._id)} className='items-center justify-center text-center   bg-red-600 text-white font-bold rounded-3xl w-full enabled:hover:bg-red-600 enabled:focus:bg-red-600 enabled:focus:ring-transparent dark:enabled:hover:bg-red-600 dark:enabled:focus:bg-red-600 dark:enabled:focus:ring-transparent mt-6'>
                                                    Disable
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <Button onClick={() => disableAccount(user._id)} className='items-center justify-center text-center   bg-red-600 text-white font-bold rounded-3xl w-full enabled:hover:bg-red-600 enabled:focus:bg-red-600 enabled:focus:ring-transparent dark:enabled:hover:bg-red-600 dark:enabled:focus:bg-red-600 dark:enabled:focus:ring-transparent mt-6'>
                                            Disable
                                        </Button>
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
};

export default UserTable;
