import { Button, Table } from 'flowbite-react';
import React from 'react';
import NothingFound from '../../components/nothingfound';
import { toast } from 'react-toastify';
import axios from 'axios';
import { serverURL } from '../../constants';

const PreCourseTable = ({ datas }) => {

    async function deleteData(id) {
        const postURL = serverURL + '/api/deleteprecourse';
        const response = await axios.post(postURL, { course: id });
        if (response.data.success) {
            showToast(response.data.message);
            window.location.reload();
        }else{
            showToast(response.data.message);
        }
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    return (
        <div className='flex flex-col py-4'>
            {datas.length === 0 ? <div className='flex items-center justify-center'><NothingFound /></div> :
                <div className="overflow-x-auto">
                    <Table>
                        <Table.Head className='border-b text-black'>
                            <Table.HeadCell className='font-black'>Title</Table.HeadCell>
                            <Table.HeadCell className='font-black'>Type</Table.HeadCell>
                            <Table.HeadCell className='font-black'>Delete</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {datas.map(courses => (
                                <Table.Row key={courses._id} className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                    <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">
                                        {courses.mainTopic}
                                    </Table.Cell>
                                    <Table.Cell className="whitespace-normal font-normal text-black dark:text-white"> {courses.type}</Table.Cell>
                                    <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">
                                    <Button onClick={()=> deleteData(courses._id)} className=' text-center font-bold max-w-md text-white bg-sky-400 rounded-3xl enabled:hover:bg-sky-400  enabled:focus:bg-sky-400  enabled:focus:ring-transparent dark:enabled:hover:bg-sky-400  dark:enabled:focus:bg-sky-400  dark:enabled:focus:ring-transparent m-4'>Delete</Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            }
        </div>

    );
};

export default PreCourseTable;
