import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import found from '../res/img/found.svg';
import { toast } from 'react-toastify';

const PreCourses = ({ userId }) => {

    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query

    useEffect(() => {
        const fetchUserCourses = async () => {
            const postURL = serverURL + `/api/getprecourses`;
            try {
                const response = await axios.get(postURL);
                setCourses(response.data);
                setProcessing(false);
            } catch (error) {
                fetchUserCourses();
            }
        };

        fetchUserCourses();
    }, [userId]);

    const navigate = useNavigate();
    function redirectGenerate() {
        navigate("/create");
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    const handleCourse = async (content, mainTopic, type, level, photo) => {
        const user = sessionStorage.getItem('uid');
        const postURL = serverURL + '/api/addPrecourse';
        const response = await axios.post(postURL, { user, content, type, mainTopic, level, photo });

        if (response.data.success) {
            showToast(response.data.message);
            sessionStorage.setItem('courseId', response.data.courseId);
            sessionStorage.setItem('first', response.data.completed);
            sessionStorage.setItem('jsonData', content);
            navigate('/course', { state: { jsonData: content, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: response.data.courseId, end: '', level: level } });
        } else {
            showToast(response.data.message);
        }
    }

    // Filter courses based on search query
    const filteredCourses = courses.filter(course =>
        course.mainTopic.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const style = {
        "root": {
            "base": "max-w-sm flex rounded-2xl  bg-slate-200 shadow-none m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }
        }
    }

    return (
        <div className='my-4'>
            {processing ? <div className="text-center h-screen w-screen flex items-center justify-center">
                <Spinner size="xl" className='fill-black dark:fill-white' />
            </div> :
                <>
                    {/* Search Input */}
                    <div className='flex flex-row max-md:flex-col'>
                        <p className='flex flex-1 mt-2 ml-2 font-black text-2xl text-black dark:text-white'>Prebuild Courses</p>
                        <input
                            className='focus:ring-black md:mr-8 mt-2 max-md:self-center rounded-3xl max-md:w-11/12 focus:border-black border border-black font-normal bg-white block dark:bg-black dark:border-white dark:text-white'
                            placeholder='Search...'
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                        />
                    </div>

                    {/* Display no courses found if empty */}
                    {filteredCourses.length === 0 ? <div className="text-center h-center flex flex-col items-center justify-center">
                        <img alt='img' src={found} className='max-w-sm h-3/6' />
                        <p className='text-black font-black dark:text-white text-xl'>Nothing Found</p>
                        <button onClick={redirectGenerate} className='bg-sky-400 rounded-3xl text-white px-5 py-2 mt-4 font-medium '>
                            Generate Course
                        </button>
                    </div> :
                        <div className='my-4 flex flex-wrap'>
                            {
                                filteredCourses.map((course) => (
                                    <Card
                                        key={course._id}
                                        imgSrc={course.photo}
                                        theme={style}
                                    >
                                        <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                            {course.mainTopic.toUpperCase()}
                                        </h5>
                                        <div className='flex flex-row mb-1'>
                                            <div className='flex-1'>
                                                <p className='font-normal text-sm capitalize mb-1 text-black dark:text-white'>
                                                    {course.type}
                                                </p>
                                                <p className='font-normal text-sm capitalize mb-1 text-black dark:text-white'>
                                                    {course.grade}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex-row flex space-x-2'>
                                            <button onClick={() => handleCourse(course.content, course.mainTopic, course.type, course.grade, course.photo)} className='bg-sky-400 text-white rounded-3xl px-4 py-2 font-medium '>
                                                Start
                                            </button>
                                        </div>
                                    </Card>
                                ))
                            }
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default PreCourses;
