import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Button } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';
import Logos from '../res/img/certificate.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image';
import { serverURL } from '../constants';
import axios from 'axios';

const Certificate = () => {

    const [processing, setProcessing] = useState(false);
    const userName = sessionStorage.getItem('mName');
    const { state } = useLocation();
    const navigate = useNavigate();
    const [modules, setModules] = useState('');
    const { courseTitle, end, jsonData, level, mail } = state || {};

    const pdfRef = useRef(null);

    const handleDownload = async () => {
        toPng(pdfRef.current, { cacheBust: false })
            .then((dataUrl) => {
                console.log(dataUrl);
                const link = document.createElement("a");
                link.download = "certificate.png";
                link.href = dataUrl;
                link.click();
                showToast("Downloaded")
            })
            .catch((err) => {
                //DO NOTHING
            });
    };


    useEffect(() => {

        if (!courseTitle) {
            navigate("/create");
        }

        init();

    }, []);

    function init() {
        let titles = '';
        jsonData[courseTitle.toLowerCase()].map(topic => {
            titles += topic.title + ', ';
        });
        setModules(titles.slice(0, -2));
    }

    async function sendMail() {
        const email = sessionStorage.getItem('email');
        toPng(pdfRef.current, { cacheBust: true })
            .then(async (dataUrl) => {
                const postURL = serverURL + '/api/sendcertificate';
                await axios.post(postURL, { html: dataUrl, email });
                console.log('sent');
            }).catch((err) => {
                console.log(err);
            });
    }

    function isValidFormat(dateString) {
        // Regex to check if date is in M/D/YY format
        const regex = /^([1-9]|1[0-2])\/([1-9]|[1-2][0-9]|3[0-1])\/\d{2}$/;
        return regex.test(dateString);
    }

    function formatDateToMDYY(date) {
        // Create a Date object from the ISO string
        const dateObj = new Date(date);

        // Handle invalid date scenarios
        if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date");
        }

        // Format the date to M/D/YY
        const month = dateObj.getMonth() + 1; // No leading zero
        const day = dateObj.getDate();
        const year = dateObj.getFullYear().toString().slice(-2); // Last two digits of the year

        return `${month}/${day}/${year}`;
    }

    function checkAndFormatDate(dateString) {
        if (isValidFormat(dateString)) {
            return dateString; // Already in M/D/YY format
        } else {
            // Assume input is in ISO 8601 format if not already valid
            return formatDateToMDYY(dateString);
        }
    }


    const showToast = async (msg) => {
        setProcessing(false);
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center py-8'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>Congratulations🎉</p>
                    <p className='text-center font-normal text-black py-4 dark:text-white'><strong>{userName}</strong> on completion of course <strong>{courseTitle}</strong>. <br></br> Download your certificate</p>
                    <Button onClick={handleDownload} isProcessing={processing} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='items-center justify-center rounded-3xl text-center dark:bg-white  bg-sky-400 text-white font-bold max-w-sm enabled:hover:bg-sky-400 enabled:focus:bg-sky-400 enabled:focus:ring-transparent dark:enabled:hover:bg-sky-400 dark:enabled:focus:bg-sky-400 dark:enabled:focus:ring-transparent' type="submit">Download</Button>
                </div>
                {modules && <div className='relative lg:mx-40 max-lg:m-20 max-md:m-2'>
                    <div onLoad={()=> {
                         if (mail) {
                            sendMail();
                        }
                    }} ref={pdfRef}>
                        <img src={Logos} alt="logo" />
                        <p className='absolute max-md:text-xs' style={{ top: '53%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {sessionStorage.getItem('mName')}
                        </p>
                        <p className='absolute max-md:text-[10px]' style={{ top: '87%', left: '18%', transform: 'translate(-50%, -50%)' }}>
                            {checkAndFormatDate(end)}
                        </p>
                        <div className='absolute left-1/3 max-md:left-[37%]' style={{ top: '72%', transform: 'translate(-50%, -50%)' }}>
                            <p className='text-sm max-md:h-3 max-md:text-[7px] capitalize'>
                                <strong>Course Title:</strong> {courseTitle}
                            </p>
                            <p className='text-sm max-md:h-3 max-md:text-[7px]'>
                                <strong>Modules Studied:</strong> {modules}
                            </p>
                            <p className='text-sm max-md:h-3 max-md:text-[7px]'>
                                <strong>Course Level:</strong> {level}
                            </p>
                        </div>
                    </div>
                </div>}
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Certificate;