import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { serverURL } from '../constants';
import axios from 'axios';

const Download = () => {
    const [load, setLoat] = useState(true);

    // Reference to track if the request has been made
    const isExecuted = useRef(false);

    // Get the current location object
    const location = useLocation();

    // Use URLSearchParams to extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); // Get 'id' parameter

    // Run once when the component renders
    const getImage = async () => {
        if (load && !isExecuted.current) {
            isExecuted.current = true; // Mark as executed
            console.log(id);
            const postURL = serverURL + '/api/getcertificate';
            const response = await axios.post(postURL, { id: id });
            setLoat(false);
            displayImage(response.data);
        }
    };

    // Invoke getImage after component mounts
    if (load) {
        getImage();
    }

    const displayImage = (data) => {
        // Create an img element
        const img = document.createElement('img');
        img.src = data;

        // Append the img element to the document body
        document.body.appendChild(img);

        // Create a download link for the image
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = 'certificate.png';

        downloadLink.click();

        // After the image is downloaded, remove the img element from the DOM
        img.remove();
    };

    return (
        <div className='h-screen flex flex-col items-center justify-center'>
            {load ?
                <p>Downloading...</p>
                :
                <p>Downloaded</p>
            }
        </div>
    );
};

export default Download;
