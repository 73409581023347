
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import found from '../res/img/found.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { toast } from 'react-toastify';

const UserCourses = ({ userId }) => {
    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [courseProgress, setCourseProgress] = useState({});
    const [searchQuery, setSearchQuery] = useState(''); // Add state for search query
    const storedTheme = sessionStorage.getItem('darkMode');

    useEffect(() => {
        const fetchUserCourses = async () => {
            const postURL = serverURL + `/api/courses?userId=${userId}`;
            try {
                const response = await axios.get(postURL);
                setCourses(response.data);
                setProcessing(false);

                const progressMap = {};
                for (const course of response.data) {
                    const progress = await CountDoneTopics(course.content, course.mainTopic, course._id);
                    progressMap[course._id] = progress;
                }
                setCourseProgress(progressMap);
            } catch (error) {
                fetchUserCourses();
            }
        };

        fetchUserCourses();
    }, [userId]);

    const navigate = useNavigate();
    function redirectGenerate() {
        navigate("/create");
    }

    const handleCourse = (content, mainTopic, type, courseId, completed, end, level) => {
        const jsonData = JSON.parse(content);
        sessionStorage.setItem('courseId', courseId);
        sessionStorage.setItem('first', completed);
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        let ending = '';
        if (completed) {
            ending = end;
        }
        sessionStorage.removeItem('result');
        navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: courseId, end: ending, level: level } });
    }

    async function handlePre(content, type, mainTopic, photo, grade) {
        let preJSON = JSON.parse(content);
        preJSON[mainTopic.toLowerCase()].map(topic => {
            topic.subtopics.map(sub => {
                if (sub.done === true) {
                    sub.done = false;
                }
            });
        });

        const preJSONString = JSON.stringify(preJSON);
        const id = toast.loading("Please wait...");
        const postURL = serverURL + '/api/precourse';
        const response = await axios.post(postURL, { content: preJSONString, type, mainTopic, photo, grade });

        if (response.data.success) {
            toast.update(id, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        } else {
            toast.update(id, { render: response.data.message, type: "error", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        }
    }

    const handleCertificate = (mainTopic, end, jsonData, level) => {
        const ending = new Date(end).toLocaleDateString();
        navigate('/certificate', { state: { courseTitle: mainTopic, end: ending, jsonData: JSON.parse(jsonData), level } });
    }

    const CountDoneTopics = async (json, mainTopic, courseId) => {
        let jsonData = JSON.parse(json);
        let doneCount = 0;
        let totalTopics = 0;
        let quiz = 0;
        jsonData[mainTopic.toLowerCase()].forEach((topic) => {
            quiz++;
            topic.subtopics.forEach((subtopic) => {
                if (subtopic.done) {
                    doneCount++;
                }
                totalTopics++;
            });
        });
        totalTopics = totalTopics + quiz;
        const quizCount = await getQuiz(courseId);
        doneCount = doneCount + quizCount;
        const completionPercentage = Math.round((doneCount / totalTopics) * 100);

        return completionPercentage;
    }

    async function getQuiz(courseId) {
        const postURL = serverURL + '/api/getresult';
        const response = await axios.post(postURL, { courseId });
        if (response.data.success) {
            const jsonGetQuiz = JSON.parse(response.data.message);
            return Object.keys(jsonGetQuiz).length;
        } else {
            return 0;
        }
    }

    const filteredCourses = courses.filter(course =>
        course.mainTopic.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const style = {
        "root": {
            "base": "max-w-sm flex rounded-2xl  bg-slate-200 shadow-none m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }
        }
    }

    return (
        <div className='my-4'>
            {processing ? (
                <div className="text-center h-screen w-screen flex items-center justify-center">
                    <Spinner size="xl" className='fill-black dark:fill-white' />
                </div>
            ) : (
                <>
                    <div className='flex flex-row max-md:flex-col'>
                        <p className='flex flex-1 mt-2 ml-2 font-black text-2xl text-black dark:text-white'>My Generated Courses</p>
                        <input
                            className='focus:ring-black md:mr-8 mt-2 max-md:self-center max-md:w-11/12 rounded-3xl focus:border-black border border-black font-normal bg-white block dark:bg-black dark:border-white dark:text-white'
                            placeholder='Search...'
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    {filteredCourses.length === 0 ? (
                        <div className="text-center h-center flex flex-col items-center justify-center">
                            <img alt='img' src={found} className='max-w-sm h-3/6' />
                            <p className='text-black font-black dark:text-white text-xl'>Nothing Found</p>
                            <button onClick={redirectGenerate} className='bg-sky-400 rounded-3xl px-5 py-2 mt-4 font-medium text-white'>
                                Generate Course
                            </button>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-wrap'>
                            {filteredCourses.map((course) => (
                                <Card key={course._id} imgSrc={course.photo} theme={style}>
                                    <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                        {course.mainTopic.toUpperCase()}
                                    </h5>
                                    <div className='flex flex-row mb-1'>
                                        <div className='flex-1'>
                                            <p className='font-normal text-sm capitalize mb-1 text-black dark:text-white'>
                                                {course.type}
                                            </p>
                                            <p className='font-normal text-sm text-black dark:text-white'>
                                                {new Date(course.date).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <div className='w-10 h-10'>
                                            <CircularProgressbar
                                                value={courseProgress[course._id] || 0}
                                                text={`${courseProgress[course._id] || 0}%`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    pathTransitionDuration: 0.5,
                                                    pathColor: storedTheme === "true" ? 'skyblue' : 'skyblue',
                                                    textColor: storedTheme === "true" ? '#fff' : '#000',
                                                    trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex-row flex space-x-2'>
                                        <button onClick={() => handleCourse(course.content, course.mainTopic, course.type, course._id, course.completed, course.end, course.grade)} className='bg-sky-400 text-white rounded-3xl px-4 py-2 font-medium '>
                                            Continue
                                        </button>
                                        {course.completed ? (
                                            <button onClick={() => handleCertificate(course.mainTopic, course.end, course.content, course.grade)} className='bg-sky-400 text-white rounded-3xl px-4 py-2 font-medium '>
                                                Certificate
                                            </button>
                                        ) : null}
                                        {sessionStorage.getItem('adminEmail') === sessionStorage.getItem('email') ? (
                                            <button onClick={() => handlePre(course.content, course.type, course.mainTopic, course.photo, course.grade)} className='bg-sky-400 rounded-3xl text-white px-4 py-2 font-medium '>
                                                Publish
                                            </button>
                                        ) : null}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserCourses;
